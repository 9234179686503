import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import type { DocNode } from '@atlaskit/adf-schema';

import { ADFRenderer, useEventHandlers } from '@confluence/adf-renderer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentRendererContainer = styled.div({
	paddingTop: `${token('space.150')}`,
	paddingBottom: `${token('space.150')}`,
	overflow: 'hidden',
});

export type CommentRendererProps = {
	adf: object;
};

export const CommentRenderer: FC<CommentRendererProps> = ({ adf }) => {
	const eventHandlers = useEventHandlers();

	return (
		<CommentRendererContainer id="ssr-comment-renderer">
			<ADFRenderer document={adf as DocNode} appearance="comment" eventHandlers={eventHandlers} />
		</CommentRendererContainer>
	);
};
