import type { CommentLocationGeneric } from '@confluence/inline-comments-queries';

// Parses out which subset of the panel comments have an inline ref on the page (i.e. all comments except page comments) as panelInlineCommentRefs
// Also parses which subset of the inline comments are navigable (i.e. rendered inline on the page, so everything except resolved comments and page comments) as navigableInlineCommentRefs
export const parsePanelAndInlineRefs = (
	currentlyRenderedThreads: CommentLocationGeneric[],
	unresolvedInlineComments: string[],
) => {
	// Grab everything on the screen as a set for quick access
	const allRenderedInlineCommentIds: Set<string> = new Set(unresolvedInlineComments);

	// Grab all of the refs for inline comments that are available on the panel
	const panelInlineCommentRefs: string[] = currentlyRenderedThreads
		.map((o: CommentLocationGeneric) => {
			if ('inlineMarkerRef' in o && o.inlineMarkerRef) {
				return o.inlineMarkerRef;
			}
			return '';
		})
		.filter((o: string) => {
			return o !== '';
		});

	// Grab the refs that we can navigate too (aka the ones rendered on the page, so not resolved comments)
	const navigableInlineCommentRefs: Set<string> = new Set(
		panelInlineCommentRefs.filter((o: string) => {
			return allRenderedInlineCommentIds.has(o);
		}),
	);
	return { panelInlineCommentRefs, navigableInlineCommentRefs };
};

export const getNextRef = (
	action: 'next' | 'previous',
	panelInlineCommentRefs: string[],
	currentlySelectedCommentMarkerRef: string | undefined,
) => {
	let nextIndex = panelInlineCommentRefs.findIndex((o: string) => {
		return o === currentlySelectedCommentMarkerRef;
	});

	if (nextIndex === -1) {
		nextIndex = 0;
	} else {
		if (action === 'next') {
			nextIndex = (nextIndex + 1) % panelInlineCommentRefs.length;
		} else {
			nextIndex = (nextIndex - 1 + panelInlineCommentRefs.length) % panelInlineCommentRefs.length;
		}
	}

	return panelInlineCommentRefs[nextIndex];
};
