import gql from 'graphql-tag';

const focusedInlineCommentFragment = gql`
	fragment focusedInlineCommentFragment on Comment {
		id
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		createdAt {
			value
		}
		createdAtNonLocalized
		isInlineComment
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			when
			number
		}
		links {
			webui
			editui
		}
		author {
			displayName
			profilePicture {
				path
			}
			permissionType
			... on KnownUser {
				accountId
			}
			... on User {
				accountId
			}
		}
	}
`;

export const FocusedInlineCommentQuery = gql`
	query FocusedInlineCommentQuery($commentId: ID!, $contentId: ID) {
		content(id: $contentId) {
			nodes {
				id
				type
				space {
					id
				}
				operations {
					operation
					targetType
				}
			}
		}
		comments(commentId: $commentId, depth: ALL, type: UNRESOLVED) {
			nodes {
				ancestors {
					...focusedInlineCommentFragment
					replies {
						...focusedInlineCommentFragment
					}
				}
				...focusedInlineCommentFragment
				replies {
					...focusedInlineCommentFragment
				}
			}
		}
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
	}
	${focusedInlineCommentFragment}
`;
