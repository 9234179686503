import { getLogger } from '@confluence/logger';

const logger = getLogger('retrieveContentExtensionsCount');

export const retrieveContentExtensionsCount = (contentBody: string) => {
	try {
		const extensionsInContent = {
			forgeExtensions: {},
			connectExtensions: {},
		};
		const document = JSON.parse(contentBody);
		document?.content?.forEach((node) => {
			const { extensionType } = node.attrs || {};
			const { type } = node;

			if (extensionType && type) {
				// Forge extensions have extensionType of 'com.atlassian.ecosystem' whereas Connect extensions have 'com.atlassian.confluence.macro.core'.
				let extensionsObj;
				if (extensionType === 'com.atlassian.ecosystem') {
					extensionsObj = extensionsInContent.forgeExtensions;
				} else if (extensionType === 'com.atlassian.confluence.macro.core') {
					extensionsObj = extensionsInContent.connectExtensions;
				}

				if (extensionsObj) {
					extensionsObj[type] = (extensionsObj[type] || 0) + 1;
				}
			}
		});
		return extensionsInContent;
	} catch {
		logger.error`Unable to parse ADF`;
		return {};
	}
};
