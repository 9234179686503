import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import CrossIcon from '@atlaskit/icon/core/close';
import MoreIcon from '@atlaskit/icon/core/show-more-horizontal';

import { CommentNavigation } from '@confluence/inline-comments-common';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarHeader = styled.div({
	position: 'relative',
	display: 'flex',
	padding: `0 ${token('space.150')} 0`,
	height: '25px',
	paddingTop: `${token('space.150')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& #dropdown': {
		marginLeft: 'auto',
		marginRight: `${token('space.150')}`,

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"& span[role='img']": {
			padding: `${token('space.025')} ${token('space.050')}`,
			borderRadius: '4px',
			height: '20px',
			width: '20px',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& #close-icon-container span:first-of-type': {
		paddingRight: `${token('space.050')}`,
		verticalAlign: 'bottom',
	},
});

type CommentHeaderProps = {
	commentIndex?: number;
	totalComments?: number;
};

export const CommentHeader: FC<CommentHeaderProps> = ({ commentIndex, totalComments }) => {
	return (
		<SidebarHeader data-testid="inline-comment-ssr-header">
			<CommentNavigation
				commentsCount={totalComments}
				currentCommentIndex={commentIndex}
				goToNextInlineComment={() => {}}
				goToPrevInlineComment={() => {}}
			/>
			<div id="dropdown">
				<MoreIcon
					label="overflow-menu-icon"
					color={token('color.icon.disabled')}
					spacing="spacious"
				/>
			</div>
			<div id="close-icon-container">
				<CrossIcon label="close-icon" color={`${token('color.icon.disabled')}`} spacing="none" />
			</div>
		</SidebarHeader>
	);
};
