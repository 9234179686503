import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ActionsContainer } from '@confluence/inline-comments-common/entry-points/styled';
import type { CommentPermissions } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { i18n } from '@confluence/inline-comments-common/entry-points/i18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainerSSR = styled(ActionsContainer)({
	minHeight: '25px',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionItem = styled.li({
	display: 'inline-flex',
	flexFlow: 'row nowrap',
	color: `${token('color.text.subtlest')}`,
	marginTop: `${token('space.050')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		alignItems: 'flex-end',
		color: `${token('color.text.brand')}`,
		flex: 2,
		textAlign: 'right',
		font: token('font.body.small'),
		paddingTop: `${token('space.050')}`,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		color: `${token('color.text.subtlest')}`,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-of-type):after': {
		content: '•',
		color: `${token('color.text.subtlest')}`,
		padding: `0 ${token('space.050')} 0 ${token('space.050')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionButton = styled.button({
	background: 'none',
	color: 'inherit',
	border: 'none',
	padding: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
});

type CommentActionsProps = {
	permissions: CommentPermissions;
	isReply: boolean;
};

export const CommentActions: FC<CommentActionsProps> = ({ isReply, permissions }) => (
	<ActionsContainerSSR>
		{permissions.isResolvable && !isReply && (
			<ActionItem>
				<ActionButton>
					<FormattedMessage {...i18n.resolve} />
				</ActionButton>
			</ActionItem>
		)}
		{permissions.isEditable && isReply && (
			<ActionItem>
				<ActionButton>
					<FormattedMessage {...i18n.edit} />
				</ActionButton>
			</ActionItem>
		)}
		{permissions.isRemovable && isReply && (
			<ActionItem>
				<ActionButton>
					<FormattedMessage {...i18n.delete} />
				</ActionButton>
			</ActionItem>
		)}
		<ReactionsPlaceholder />
	</ActionsContainerSSR>
);
