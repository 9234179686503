import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { COVERPICTUREWIDTH } from '@confluence/content-topper';
import { fg } from '@confluence/feature-gating';

import type { RendererPageCoverPictureComponentProps } from '../pageCoverPictureTypes';
import {
	CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	COVER_IMAGE_FIXED_WIDTH,
	COVER_IMAGE_FIXED_HEIGHT,
} from '../pageCoverPictureConstants';

type PlaceholderProps = {
	contentAppearance: string;
	isPagePreview: boolean;
	isSpaceOverview?: boolean;
	hasEmoji?: boolean;
	isFixedWidthImageOption?: boolean;
	isObjectHeaderEnabled?: boolean;
};

/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/use-tokens-space, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766 */
const Placeholder = styled.div<PlaceholderProps>({
	...({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption
			? {
					marginTop: '-10px',
					width: `${COVER_IMAGE_FIXED_WIDTH}px`,
					margin: '0 auto',
				}
			: {
					marginTop: '-10px',
					width: 'var(--full-cover-image-width, 100%)',
					marginLeft: 'var(--full-cover-image-margin, 0px)',
				},
	marginBottom: (props) =>
		props.isObjectHeaderEnabled
			? token('space.0')
			: props.isPagePreview || (props.isSpaceOverview && props.hasEmoji)
				? token('space.200')
				: token('space.400'),
	backgroundColor: token('color.background.neutral'),
	height: ({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption ? `${COVER_IMAGE_FIXED_HEIGHT}px` : CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
});
/* eslint-enable @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/use-tokens-space, @atlaskit/ui-styling-standard/no-styled */

export const RendererPageCoverPicturePlaceholder = ({
	coverPicture,
	isPagePreview,
	contentAppearance,
	isSpaceOverview,
	hasEmoji,
	coverPictureWidthOverride,
}: RendererPageCoverPictureComponentProps) => {
	const hasCoverPicture = isPagePreview
		? !!coverPicture?.draft?.value?.id
		: !!coverPicture?.published?.value?.id;
	const appearance = isPagePreview ? contentAppearance.draft : contentAppearance.published;

	const isFixedWidthImageOption = coverPictureWidthOverride === COVERPICTUREWIDTH.FIXED;

	return hasCoverPicture ? (
		<div>
			<Placeholder
				contentAppearance={appearance}
				isPagePreview={isPagePreview}
				isSpaceOverview={isSpaceOverview}
				hasEmoji={hasEmoji}
				isFixedWidthImageOption={isFixedWidthImageOption}
				isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
			/>
		</div>
	) : null;
};
